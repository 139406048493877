import React from "react"
import "../components/top.css"
import { Helmet } from "react-helmet"
import { Grid } from "@material-ui/core"
import Layout from "../components/layout"
import Footer from "../components/footer"
import { ContactForm } from "../components/ContactForm"
import { ContactHeader } from "../components/ContactHeader"
import { ContactTitle } from "../components/ContactTitle"
import CookieConsent from "react-cookie-consent"

const Contacts = () => {
  const parse = url => {
    const urlSplit = url.split("?")
    return urlSplit.length > 0
      ? [...new URLSearchParams(urlSplit[1]).entries()].reduce(
          (obj, e) => ({ ...obj, [e[0]]: e[1] }),
          {}
        )
      : ""
  }
  const roomId =
    parse(typeof window !== `undefined` ? window.location.href : "") &&
    parse(typeof window !== `undefined` ? window.location.href : "").room
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&family=Roboto:wght@400;500;700;900&display=swap"
          rel="stylesheet"
        />

        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&family=Roboto:wght@400;500;700;900&display=swap"
          rel="stylesheet"
        />
        <title>コンタクト- A YOTSUYA</title>
        <meta name="author" content="できるネット編集部" />
        <meta
          name="title"
          content="A YOTSUYA - アートで部屋を選ぶシェアオフィス"
        />
        <meta
          name="description"
          content="新たな「ワークスタイル」を実現する、クリエイティブ・アートオフィス「A YOTSUYA」の公式サイトです。総勢10名のアーティストにより描かれた26面のミューラルアート、ブルックリンをコンセプトにした空間があなたのクリエイティビティを刺激し、新しい働き方を提供します。"
        />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-LXB6VM7JPL"
        ></script>
        <script>
          {`
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-LXB6VM7JPL');`}
        </script>
      </Helmet>
      <ContactHeader />
      <CookieConsent
        location="bottom"
        buttonText="閉じる"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{
          color: "#fff",
          background: "#f00",
          fontSize: "13px",
          border: "none",
          fontFamily: "Noto Sans JP,sans-serif",
        }}
        expires={150}
      >
        <p style={{ fontFamily: "Noto Sans JP,sans-serif" }}>
          当ウェブサイトはCookieを使用しており、引き続き当サイトを閲覧される場合、当社の個人情報保護方針に同意したものといたします。
        </p>
        <a
          style={{
            textDecoration: "underline",
            fontFamily: "Noto Sans JP,sans-serif",
          }}
          href="https://www.sunfrt.co.jp/policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span
            style={{ fontSize: "10px", fontFamily: "Noto Sans JP,sans-serif" }}
          >
            個人情報保護方針
          </span>
        </a>
      </CookieConsent>
      <Layout>
        <Grid
          container
          style={{
            minHeight: "50px",
            backgroundColor: "#E8DED3",
          }}
        >
          <Grid item xs={12}>
            <ContactTitle />
            <ContactForm selectRoomId={roomId} />
          </Grid>
        </Grid>
        <Footer isContact />
      </Layout>
    </>
  )
}

export default Contacts
