import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { useStaticQuery } from "gatsby"

export const ContactTitle = () => {
  const data = useStaticQuery(graphql`
    query {
      contact: file(relativePath: { eq: "contactBlack.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <PageTitle>
      <Img fluid={data.contact.childImageSharp.fluid} />
    </PageTitle>
  )
}

const PageTitle = styled.div`
  margin: auto;
  @media screen and (max-width: 600px) {
    margin-bottom: 24px;
    max-width: 180px;
  }
  @media screen and (min-width: 600px) and (max-width: 1280px) {
    margin-bottom: 40px;
    max-width: 255px;
  }
  @media screen and (min-width: 1280px) {
    margin-bottom: 40px;
    max-width: 255px;
  }
`
