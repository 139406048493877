import React from "react"
import { Box, Grid, Hidden } from "@material-ui/core"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

export const ContactHeader = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "headerArtBlack.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <Grid container style={{ backgroundColor: "#E8DED3" }}>
        {/* Header */}
        <Hidden mdDown>
          <Grid item xs={3}>
            <Box ml={4} mb={2} mt={1}>
              <a href="/#top">
                <Img
                  style={{ maxWidth: "134px" }}
                  fluid={data.placeholderImage.childImageSharp.fluid}
                />
              </a>
            </Box>
          </Grid>
        </Hidden>
        <Hidden lgUp>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <Grid container>
              <Grid item xs={4} />
              <Grid item xs={4}>
                <Box mb={2} mt={1}>
                  <a href="/#top">
                    <Img
                      style={{
                        maxWidth: "134px",
                        textAlign: "center",
                      }}
                      fluid={data.placeholderImage.childImageSharp.fluid}
                    />
                  </a>
                </Box>
              </Grid>
              <Grid item xs={4} />
            </Grid>
          </Grid>
          <Grid item xs={4} />
        </Hidden>
      </Grid>
    </>
  )
}
